var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.salvar.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-group',[_c('label',[_vm._v("Numero")]),_c('b-form-input',{model:{value:(_vm.estabelecimento.numero),callback:function ($$v) {_vm.$set(_vm.estabelecimento, "numero", $$v)},expression:"estabelecimento.numero"}})],1)],1),_c('div',{staticClass:"col"},[_c('label',{attrs:{"for":""}},[_vm._v("Fornecedor")]),_c('select-paciente',{attrs:{"setNullOnEmpty":"","showBotaoEdit":false,"showBotaoAdd":false,"selecionado":_vm.estabelecimento.fornecedor,"tipo":['fornecedor']},on:{"paciente":_vm.onFornecedor}})],1),_c('div',{staticClass:"col"},[_c('b-form-group',[_c('SelectClinicas',{attrs:{"label":"Empresa"},model:{value:(_vm.estabelecimento.empresa_id),callback:function ($$v) {_vm.$set(_vm.estabelecimento, "empresa_id", $$v)},expression:"estabelecimento.empresa_id"}})],1)],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('b-form-group',[_c('select-bandeira',{attrs:{"customReduce":(value) => value,"icon":""},on:{"input":function($event){return _vm.addBandeira($event)}}})],1)],1)]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('b-table',{staticClass:"shadow",attrs:{"striped":"","hover":"","responsive":"sm","stacked":"sm","small":"","outlined":"","fields":_vm.campos,"items":_vm.bandeirasFiltradas,"per-page":_vm.paginacao.registros_por_pagina,"current-page":_vm.paginacao.pagina_atual,"show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" Nenhuma bandeira por enquanto. "),_c('i',{staticClass:"fas fa-store"})])]},proxy:true},{key:"cell(credito)",fn:function({item}){return [_c('i',{class:[
                { 'fas fa-check': !!item.credito },
                { 'fas fa-times': !item.credito },
              ]})]}},{key:"cell(debito)",fn:function({item}){return [_c('i',{class:[
                { 'fas fa-check': !!item.debito },
                { 'fas fa-times': !item.debito },
              ]})]}},{key:"cell(actions)",fn:function({item}){return [_c('b-btn',{attrs:{"size":"sm","variant":"transparent"},on:{"click":function($event){return _vm.removeBandeira(item.id)}}},[_c('i',{staticClass:"fas fa-trash"})])]}}])}),_c('div',{staticClass:"row d-flex align-items-baseline"},[_c('div',{staticClass:"col-6"},[_c('b-pagination',{attrs:{"per-page":_vm.paginacao.registros_por_pagina,"total-rows":_vm.bandeirasFiltradas.length},model:{value:(_vm.paginacao.pagina_atual),callback:function ($$v) {_vm.$set(_vm.paginacao, "pagina_atual", $$v)},expression:"paginacao.pagina_atual"}})],1)])],1)]),_c('div',{staticClass:"bottom-actions pt-3 border-top"},[_c('b-btn',{staticClass:"mr-3",attrs:{"variant":"primary","type":"submit"}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" Salvar ")]),_c('b-btn',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('i',{staticClass:"fas fa-ban"}),_vm._v(" Cancelar ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }