<template>
  <div>
    <barra-crud :botaoNovo="true" :onNovo="novo" :onFiltrar="filtrar" />
    <estabelecimentos-table
      @editar="editar($event)"
      @excluir="excluir($event)"
      :estabelecimentos="estabelecimentos"
      @novo="novo"
    ></estabelecimentos-table>

    <b-modal
      content-class="modalDireito"
      id="modal-estabelecimentos"
      hide-footer
      no-fade
    >
      <template #modal-title>
        <img
          src="../../../src/assets/img/icones/menu/online_store_40px_dark.png"
        />
        Cadastrar/Editar Estabelecimento
      </template>
      <estabelecimentos-form
        @salvou="onSalvou"
        @close="close"
        :id="id"
      ></estabelecimentos-form>
    </b-modal>
  </div>
</template>

<script>
import EstabelecimentosForm from "../../components/Estabelecimentos/EstabelecimentosForm.vue";
import EstabelecimentosTable from "../../components/Estabelecimentos/EstabelecimentoTable.vue";
import EstabelecimentosLib from "../../libs/EstabelecimentosLib";
import BarraCrud from "@/components/common/BarraCrud";
export default {
  components: {
    BarraCrud,
    EstabelecimentosForm,
    EstabelecimentosTable,
  },
  data() {
    return {
      filtro: "",
      id: "",
      estabelecimentos: []
    };
  },
  methods: {
    filtrar(filtro) {
      this.filtro = filtro;
    },
    async carregar() {
      try {
        this.estabelecimentos = await EstabelecimentosLib.get();
      } catch (error) {
        this.showToast(
          "error",
          `Erro ao carregar estabelecimentos ${error.message}`
        );
      }
    },
    onSalvou() {
      this.close();
      this.id = null;
      this.carregar()
    },
    novo() {
      this.id = null;
      this.$bvModal.show("modal-estabelecimentos");
    },
    close() {
      this.$bvModal.hide("modal-estabelecimentos");
    },
    editar(id) {
      this.id = id;
      this.$bvModal.show("modal-estabelecimentos");
    },
    async excluir(id) {
      if (id) {
        this.$swal
          .fire({
            title: `Você tem certeza que deseja excluir este estabelecimento?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (res) => {
            if (res.isConfirmed) {
              try {
                const result = await EstabelecimentosLib.del(id);
                // console.log(result);
                if (result && result.status === 200) {
                  this.$swal.fire({
                    title: `Estabelecimento excluído com sucesso!`,
                    icon: "success",
                    toast: true,
                    timer: 1500,
                    position: "top-end",
                    showConfirmButton: false,
                  });
                  await this.carregar();
                } else {
                  // console.log(result);
                  this.$swal.fire({
                    title: `Erro ao excluir! ${result.message}`,
                    icon: "error",
                    toast: true,
                    timer: 10000,
                    position: "top-end",
                    showConfirmButton: false,
                  });
                }
              } catch (error) {
                console.warn(error);
                this.$swal.fire({
                  title: `Erro ao excluir estabelecimento!`,
                  icon: "error",
                  toast: true,
                  timer: 1500,
                  position: "top-end",
                  showConfirmButton: false,
                });
              }
            }
          });
      }
    },
  },
  mounted(){
    this.carregar()
  }
};
</script>

<style></style>
