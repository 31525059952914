<template>
  <div>
    <b-form @submit.prevent.stop="salvar">
      <div class="row">
        <div class="col">
          <b-form-group>
            <label>Numero</label>
            <b-form-input v-model="estabelecimento.numero"> </b-form-input>
          </b-form-group>
        </div>
        <div class="col">
          <label for="">Fornecedor</label>
          <select-paciente
          setNullOnEmpty
          :showBotaoEdit="false"
          :showBotaoAdd="false"
            :selecionado="estabelecimento.fornecedor"
            @paciente="onFornecedor"
            :tipo="['fornecedor']"
          ></select-paciente>
        </div>
        <div class="col">
          <b-form-group>
            <SelectClinicas
              label="Empresa"
              v-model="estabelecimento.empresa_id"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12">
          <b-form-group>
            <select-bandeira
              :customReduce="(value) => value"
              icon
              @input="addBandeira($event)"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive="sm"
            stacked="sm"
            small
            outlined
            class="shadow"
            :fields="campos"
            :items="bandeirasFiltradas"
            :per-page="paginacao.registros_por_pagina"
            :current-page="paginacao.pagina_atual"
            show-empty
          >
            <template #empty>
              <div class="text-center">
                Nenhuma bandeira por enquanto.
                <i class="fas fa-store"></i>
              </div>
            </template>

            <template #cell(credito)="{item}">
              <i
                :class="[
                  { 'fas fa-check': !!item.credito },
                  { 'fas fa-times': !item.credito },
                ]"
              ></i>
            </template>
            <template #cell(debito)="{item}">
              <i
                :class="[
                  { 'fas fa-check': !!item.debito },
                  { 'fas fa-times': !item.debito },
                ]"
              ></i>
            </template>

            <template #cell(actions)="{item}">
              <b-btn
                size="sm"
                variant="transparent"
                @click="removeBandeira(item.id)"
              >
                <i class="fas fa-trash"></i>
              </b-btn>
            </template>
          </b-table>
          <div class="row d-flex align-items-baseline">
            <div class="col-6">
              <b-pagination
                v-model="paginacao.pagina_atual"
                :per-page="paginacao.registros_por_pagina"
                :total-rows="bandeirasFiltradas.length"
              >
              </b-pagination>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-actions pt-3 border-top">
        <b-btn variant="primary" type="submit" class="mr-3">
          <i class="fas fa-save"></i> Salvar
        </b-btn>
        <b-btn variant="secondary" @click="$emit('close')">
          <i class="fas fa-ban"></i> Cancelar
        </b-btn>
      </div>
    </b-form>
  </div>
</template>

<script>
import EstabelecimentosLib from "../../libs/EstabelecimentosLib";
import SelectClinicas from "../common/SelectClinicas.vue";
import SelectPaciente from "../common/SelectPaciente.vue";
import SelectBandeira from "../common/SelectBandeira.vue";

export default {
  components: {
    SelectClinicas,
    SelectPaciente,
    SelectBandeira,
  },
  props: {
    id: [String, Number],
  },
  data() {
    return {
      estabelecimento: {
        numero: "",
        fornecedor: null,
        empresa_id: null,
        bandeiras: [],
      },
      teste: "",
      campos: [
        {
          key: "nome",
          class: "text-center",
        },
        {
          key: "debito",
          label: "Débito",
          class: "text-center",
        },
        {
          key: "credito",
          label: "Crédito",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
        },
      ],
      paginacao: {
        registros_por_pagina: 5,
        pagina_atual: 1,
      },
    };
  },
  methods: {
    onFornecedor(fornecedor) {
      if (!fornecedor || !fornecedor?.id) {
        this.estabelecimento.fornecedor = null;
        return;
      }
      this.estabelecimento.fornecedor = fornecedor.id;
    },
    validar() {
      let validacao = { validou: true, msg: [] };
      if (this.estabelecimento.numero == "") {
        validacao.validou = false;
        validacao.msg.push("informe o número do estabelecimento!");
      }
      if (!this.estabelecimento.fornecedor) {
        validacao.validou = false;
        validacao.msg.push("informe o fornecedor do estabelecimento!");
      }
      if (!this.estabelecimento.empresa_id) {
        validacao.validou = false;
        validacao.msg.push("informe a empresa do estabelecimento!");
      }
      if (
        this.estabelecimento.bandeiras &&
        this.estabelecimento.bandeiras.length == 0
      ) {
        validacao.validou = false;
        validacao.msg.push("informe ao menos uma bandeira!");
      }

      return validacao;
    },
    async salvar() {
      let valida = this.validar();
      // console.log("validou?", valida);
      // console.log("solicitacao:", this.solicitacao);
      if (valida.validou) {
        try {
          let result = await EstabelecimentosLib.store(this.estabelecimento);
          if (result.status == 201 || result.status == 200) {
            this.showToast("success", "Estabelecimento salvo com sucesso!");
            this.$emit("salvou");
          }
        } catch (error) {
          this.showToast("error", error);
        }
      } else {
        this.$swal.fire({
          title: "Erro",
          icon: "error",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${valida.msg.reduce(
            (it, vl) => {
              it += vl + " <hr />";
              return it;
            },
            ""
          )} `,
        });
      }
    },
    addBandeira(bandeira) {
      if (
        this.estabelecimento.bandeiras &&
        this.estabelecimento.bandeiras.some((e) => e.id == bandeira.id)
      ) {
        this.showToast(
          "error",
          "Essa bandeira já foi adicionada a esse estabelecimento."
        );
        return;
      }
      if (bandeira) {
        this.estabelecimento.bandeiras.push({
          ...bandeira,
          _rowStatus: "added",
        });
      }
    },
    removeBandeira(id) {
      if (id) {
        const i = this.estabelecimento.bandeiras.findIndex((e) => e.id == id);
        const bandeira = this.estabelecimento.bandeiras[i];

        if (bandeira) {
          this.$swal
            .fire({
              title: `Você tem certeza que deseja remover está bandeira?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Sim. Remover!",
              cancelButtonText: "Não.",
            })
            .then(async (res) => {
              if (res.isConfirmed) {
                if (bandeira._rowStatus && bandeira._rowStatus == "added") {
                  this.estabelecimento.bandeiras.splice(i, 1);
                } else {
                  this.$set(bandeira, "_rowStatus", "deleted");
                }
              }
            });
        }
      }
    },
  },
  async mounted() {
    if (this.id) {
      this.estabelecimento = (await EstabelecimentosLib.get(this.id))[0];
    }
  },
  computed: {
    bandeirasFiltradas() {
      return this.estabelecimento.bandeiras.filter((e) =>
        e._rowStatus ? (e._rowStatus == "deleted" ? false : true) : true
      );
    },
  },
};
</script>

<style></style>
